import React from 'react';
import { graphql } from 'gatsby';

import { ProducthuntPageLanding } from 'components/Producthunt';

export default function PageLandingProducthunt(props) {
  return <ProducthuntPageLanding {...props} />;
}

export const query = graphql`
  query PageLandingProducthuntQuery {
    page: strapiLandingProducthunt {
      seo {
        title
        description
        keywords
      }
      heading
      text {
        data {
          text
        }
      }
      gallery {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
          }
        }
      }
    }
  }
`;
