import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Carousel } from 'react-responsive-carousel';

import { IconArrowRight } from 'assets';

import { Page } from 'components/_shared/Page';
import { Action } from 'components/_shared/Action';
import { Markdown } from 'components/_shared/Markdown';

import { useStrapiActions } from 'hooks';

import './ProducthuntPageLanding.styles.scss';

export const ProducthuntPageLanding = ({ data: { page } }) => {
  const action = useStrapiActions().getActionBySlug('open-beta-get-started');

  return (
    <Page
      className='landing producthunt'
      meta={page.seo}
      template='v2'
      container={{ variant: 'wide' }}
      headerProps={{ signUpButton: { color: 'pureBlue' } }}
    >
      {!!page.gallery?.length && (
        <div className='col'>
          <Carousel
            animationHandler='fade'
            autoPlay
            infiniteLoop
            stopOnHover={false}
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            showIndicators={false}
            transitionTime={800}
          >
            {page.gallery.map(image => {
              return (
                image?.localFile && (
                  <div key={image.alternativeText}>
                    <GatsbyImage
                      image={getImage(image.localFile)}
                      alt={image.alternativeText}
                    />
                  </div>
                )
              );
            })}
          </Carousel>
        </div>
      )}

      <div className='col'>
        <div className='text-wrapper'>
          <h2 className='heading'>{page.heading}</h2>
          <Markdown
            markdown={page.text.data.text}
            className='text'
            variant='custom'
          />
        </div>

        {action && (
          <div className='action-wrapper'>
            <Action
              {...action}
              asStyles='button'
              variant='quaternary'
              size='sm'
              color='pureBlue'
              icon={<IconArrowRight />}
              iconPosition='right'
              isIconFloat
              target='_self'
            />
          </div>
        )}
      </div>
    </Page>
  );
};
